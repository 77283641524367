import { createRouter,createWebHashHistory,createWebHistory} from "vue-router";


const routes = [
  {
    path: '/login',
    name:'登录',
    component: () => import('../viwes/Login/Login'),
    meta: {requireAuth: true},
    children: []
   }, 

   {
        path: '/',
        name:'',
        component: () => import('../viwes/Index/Index'),
        meta: {requireAuth: true},
        children: [
          {
            path: '/data',
            name:'数据页面',
            component: () => import('../viwes/Data/Data'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/userbuyer',
            name:'用户管理',
            component: () => import('../viwes/User/UserBuyer'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/userseller',
            name:'商家列表',
            component: () => import('../viwes/User/UserSeller'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/shoplist',
            name:'店铺列表',
            component: () => import('../viwes/Shop/ShopList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/orderlist',
            name:'订单列表',
            component: () => import('../viwes/Orders/OrdersList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/config',
            name:'系统配置',
            component: () => import('../viwes/Other/Config'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/typelist',
            name:'分类管理',
            component: () => import('../viwes/Other/TypeList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/gtypeList',
            name:'商品分类',
            component: () => import('../viwes/Goods/GtypeList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/gattribute',
            name:'商品属性',
            component: () => import('../viwes/Goods/GattriBute'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/glist',
            name:'商品列表',
            component: () => import('../viwes/Goods/GList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/shopaudit',
            name:'店铺审核',
            component: () => import('../viwes/Shop/ShopAudit'),
            meta: {requireAuth: true},
            children: []
          }
         
        ]
     }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;